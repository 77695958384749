import { useState } from 'react'
import { Button, Card, CardBody } from '@nextui-org/react'
import { migrateToUserSubcollections } from '@/scripts/migrate-data'
import { useAuth } from '@/stores/auth-context'

export function MigratePage() {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState<string | null>(null)
  const { user } = useAuth()

  const handleMigration = async () => {
    if (!user) return
    
    setIsLoading(true)
    setError(null)
    setSuccess(false)
    setMessage(null)

    try {
      const result = await migrateToUserSubcollections(user.uid)
      if (result.success) {
        setSuccess(true)
        setMessage(result.message || null)
      } else {
        setError('Migration failed. Please check the console for details.')
      }
    } catch (err) {
      setError('An unexpected error occurred during migration.')
      console.error('Migration error:', err)
    } finally {
      setIsLoading(false)
    }
  }

  if (!user) {
    return (
      <div className="flex items-center justify-center h-full">
        <Card>
          <CardBody>
            <p className="text-foreground/60">Please log in to access this page.</p>
          </CardBody>
        </Card>
      </div>
    )
  }

  return (
    <div className="container mx-auto p-4 max-w-2xl">
      <Card className="glass-panel">
        <CardBody className="space-y-4">
          <h1 className="text-2xl font-bold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
            Data Migration Tool
          </h1>
          
          <p className="text-foreground/60">
            This tool will migrate your mood data to a new, more efficient structure.
            The migration process is safe and your data will not be lost.
          </p>

          <div className="space-y-2">
            <Button
              color="primary"
              onClick={handleMigration}
              isLoading={isLoading}
              className="w-full"
              size="lg"
            >
              Start Migration
            </Button>

            {error && (
              <p className="text-danger text-sm mt-2">{error}</p>
            )}

            {success && (
              <p className="text-success text-sm mt-2">
                Migration completed successfully! {message}
              </p>
            )}
          </div>

          <div className="text-sm text-foreground/60 space-y-2">
            <p className="font-semibold">What this migration does:</p>
            <ul className="list-disc list-inside space-y-1">
              <li>Reorganizes your mood entries for better performance</li>
              <li>Creates a dedicated space for your user profile</li>
              <li>Improves data security and privacy</li>
              <li>Makes the app faster and more efficient</li>
            </ul>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}
