import { format, parse } from 'date-fns'

export function formatTimeToAmPm(time: string): string {
  // Handle empty input
  if (!time) return ''

  try {
    // If time is already in AM/PM format, return it
    if (time.includes('AM') || time.includes('PM')) {
      // Remove any seconds and leading zeros if present
      return time.replace(/:\d{2}\s/, ' ').replace(/^0/, '')
    }

    // Parse 24h time (HH:mm) to Date object
    const [hours, minutes] = time.split(':').map(Number)
    const date = new Date()
    date.setHours(hours)
    date.setMinutes(minutes)

    // Format to "h:mm a" (e.g., "9:49 PM") - no leading zeros
    return format(date, 'h:mm a')
  } catch (error) {
    console.error('Error formatting time:', error)
    return time
  }
}

export function parseAmPmTo24Hour(time: string): string {
  if (!time) return ''

  try {
    // If already in 24h format (HH:mm), return as is
    if (time.match(/^\d{2}:\d{2}$/)) {
      return time
    }

    // Parse the AM/PM time
    const match = time.match(/(\d{1,2}):(\d{2}) (AM|PM)/)
    if (!match) return time

    let [_, hours, minutes, period] = match
    let hour = parseInt(hours)
    
    if (period === 'PM' && hour !== 12) {
      hour += 12
    } else if (period === 'AM' && hour === 12) {
      hour = 0
    }

    return `${hour.toString().padStart(2, '0')}:${minutes}`
  } catch (error) {
    console.error('Error parsing time:', error)
    return time
  }
}
