import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import { ArrowPathIcon } from '@heroicons/react/24/outline'

interface MigrationPromptProps {
  isOpen: boolean
  onClose: () => void
}

export function MigrationPrompt({ isOpen, onClose }: MigrationPromptProps) {
  const navigate = useNavigate()

  const handleMigrate = () => {
    navigate('/migrate')
    onClose()
  }

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="lg"
      classNames={{
        backdrop: "bg-black/50 backdrop-blur-sm",
        base: "border-0",
        body: "py-6",
        header: "border-b-0",
        footer: "border-t-0",
        closeButton: "hover:bg-white/5 active:bg-white/10"
      }}
    >
      <ModalContent>
        <div className="p-6">
          <div className="flex flex-col gap-6">
            {/* Header with Icon */}
            <div className="flex items-center gap-3">
              <div className="h-10 w-10 rounded-xl bg-primary/10 flex items-center justify-center">
                <ArrowPathIcon className="h-5 w-5 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
                  Data Migration Required
                </h3>
              </div>
            </div>

            {/* Content */}
            <div className="space-y-4">
              <div className="glass-panel rounded-xl p-4 bg-gradient-to-b from-background/80 to-background/40 backdrop-blur-xl">
                <p className="text-foreground/90">
                  We&apos;ve made some improvements to how your mood data is stored.
                  To continue using the app with all your existing data, you&apos;ll need
                  to migrate your mood entries to the new format.
                </p>
              </div>
              
              <p className="text-sm text-foreground/60 px-1">
                This is a one-time process and will only take a few seconds. Please let the dev know how this goes.
              </p>
            </div>

            {/* Actions */}
            <div className="flex gap-3">
              <Button
                className="flex-1 h-12 bg-primary/10 text-primary rounded-xl backdrop-blur-xl"
                onPress={handleMigrate}
              >
                Start Migration
              </Button>
              <Button
                className="flex-1 h-12 bg-foreground/5 text-foreground/60 rounded-xl backdrop-blur-xl"
                onPress={onClose}
              >
                Later
              </Button>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}
