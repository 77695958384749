import { useQuery, useQueryClient } from '@tanstack/react-query'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { db } from '@/lib/utils/firebase'
import { useAuth } from '@/stores/auth-context'
import { format } from 'date-fns' // Import the format function from date-fns

export function useMoodData(currentMonth: Date) {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const now = new Date()
  const isCurrentMonth = currentMonth.getMonth() === now.getMonth() && 
                        currentMonth.getFullYear() === now.getFullYear()

  return useQuery({
    queryKey: ['monthlyMoods', currentMonth.toISOString()],
    queryFn: async () => {
      if (!user) throw new Error('User not authenticated')

      // Create dates in local timezone
      const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1)
      startOfMonth.setHours(0, 0, 0, 0)
      
      // Always use the last day of the month for the date range
      const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0)
      endOfMonth.setHours(23, 59, 59, 999)

      const moodsRef = collection(db, 'users', user.uid, 'moods')
      const q = query(
        moodsRef,
        where('timestamp', '>=', startOfMonth),
        where('timestamp', '<=', endOfMonth)
      )

      try {
        const querySnapshot = await getDocs(q)
        const moodsByDate = new Map<string, number[]>()
        
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          // Use the stored date string directly without any conversion
          const dateStr = data.date
          
          if (!moodsByDate.has(dateStr)) {
            moodsByDate.set(dateStr, [])
          }
          moodsByDate.get(dateStr)?.push(data.moodScore)
        })

        const dates: string[] = []
        const averageMoods: number[] = []

        // Iterate through the month using the same date format
        let currentDate = new Date(startOfMonth)
        while (currentDate <= endOfMonth) {
          const dateStr = format(currentDate, 'yyyy-MM-dd')
          dates.push(dateStr)
          
          const moods = moodsByDate.get(dateStr)
          if (moods && moods.length > 0) {
            const average = moods.reduce((sum, score) => sum + score, 0) / moods.length
            averageMoods.push(Number(average.toFixed(1)))
          } else {
            averageMoods.push(undefined as any)
          }
          
          currentDate.setDate(currentDate.getDate() + 1)
        }

        return {
          dates,
          averageMoods
        }
      } catch (error) {
        throw error
      }
    },
    enabled: !!user,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchInterval: isCurrentMonth ? 1000 : false
  })
}