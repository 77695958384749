import { doc, setDoc, getDoc } from 'firebase/firestore'
import { db } from '@/lib/utils/firebase'

export interface UserProfile {
  displayName: string | null
  createdAt: Date
  updatedAt: Date
}

export async function updateUserProfile(userId: string, data: Partial<UserProfile>) {
  const userRef = doc(db, 'users', userId, 'profile', 'data')
  
  try {
    const docSnap = await getDoc(userRef)
    const currentData = docSnap.data() as UserProfile | undefined
    
    const updatedData = {
      ...currentData,
      ...data,
      updatedAt: new Date()
    }

    // If this is a new profile, add createdAt
    if (!currentData) {
      updatedData.createdAt = new Date()
    }

    await setDoc(userRef, updatedData)
    return { success: true, data: updatedData }
  } catch (error) {
    console.error('Error updating user profile:', error)
    return { success: false, error }
  }
}

export async function getUserProfile(userId: string) {
  const userRef = doc(db, 'users', userId, 'profile', 'data')
  
  try {
    const docSnap = await getDoc(userRef)
    if (docSnap.exists()) {
      return { success: true, data: docSnap.data() as UserProfile }
    }
    return { success: true, data: null }
  } catch (error) {
    console.error('Error getting user profile:', error)
    return { success: false, error }
  }
}
