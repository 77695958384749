import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Textarea, Input } from '@nextui-org/react'
import { PageTransition } from '@/components/ui/page-transition'
import { MoodSlider } from '@/components/mood/mood-slider'
import { createMoodEntry } from '@/services/mood'
import { useAuth } from '@/stores/auth-context'
import { format, parse } from 'date-fns'
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/outline'
import { useQueryClient, useMutation } from '@tanstack/react-query'

const moodDescriptions = {
  0: 'Severe Depression - Very low energy, withdrawn',
  1: 'Deep Depression - Low energy, isolated',
  2: 'Moderate Depression - Limited energy',
  3: 'Mild Depression - Below normal energy',
  4: 'Slightly Low - Just below baseline',
  5: 'Neutral - Balanced energy level',
  6: 'Slightly Elevated - More active than usual',
  7: 'Elevated - High energy, very active',
  8: 'Highly Elevated - Excessive energy',
  9: 'Hypomania - Racing thoughts, overactive',
  10: 'Mania - Extreme energy, potentially risky'
}

function getMoodColor(score: number) {
  if (score <= 2) return 'from-red-500/80 to-red-700/60' // Deep depression
  if (score <= 4) return 'from-orange-500/80 to-orange-700/60' // Mild depression
  if (score === 5) return 'from-blue-500/80 to-blue-700/60' // Neutral
  if (score <= 7) return 'from-green-500/80 to-green-700/60' // Elevated
  return 'from-purple-500/80 to-purple-700/60' // Mania
}

export function InputPage() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { user } = useAuth()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [moodScore, setMoodScore] = useState(5)
  const [notes, setNotes] = useState('')
  
  // Get date from URL params or use today's date
  const urlDate = searchParams.get('selectedDate')
  const [selectedDate, setSelectedDate] = useState(urlDate || format(new Date(), 'yyyy-MM-dd'))
  const [selectedTime, setSelectedTime] = useState(format(new Date(), 'HH:mm'))

  // Ensure the date is not in the future
  useEffect(() => {
    const selectedDateTime = new Date(selectedDate)
    if (selectedDateTime > new Date()) {
      setSelectedDate(format(new Date(), 'yyyy-MM-dd'))
    }
  }, [selectedDate])

  const mutation = useMutation({
    mutationFn: createMoodEntry,
    onMutate: async (newEntry) => {
      // Cancel outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['moodStats'] })
      await queryClient.cancelQueries({ queryKey: ['dailyMoods'] })
      await queryClient.cancelQueries({ queryKey: ['monthlyMoods'] })
      
      // Snapshot previous values
      const previousStats = queryClient.getQueryData(['moodStats'])
      const previousDailyMoods = queryClient.getQueryData(['dailyMoods', newEntry.date])
      
      return { previousStats, previousDailyMoods }
    },
    onError: (err, newEntry, context) => {
      // Rollback on error
      if (context) {
        queryClient.setQueryData(['moodStats'], context.previousStats)
        queryClient.setQueryData(['dailyMoods', newEntry.date], context.previousDailyMoods)
      }
    },
    onSuccess: () => {
      navigate(`/day?date=${selectedDate}`)
    },
    onSettled: () => {
      // Get the current month for the monthly moods query
      const currentMonth = new Date()
      currentMonth.setHours(0, 0, 0, 0) // Reset time components
      
      // Refetch to ensure consistency
      queryClient.invalidateQueries({ queryKey: ['moodStats'] })
      queryClient.invalidateQueries({ queryKey: ['dailyMoods'] })
      queryClient.invalidateQueries({ 
        queryKey: ['monthlyMoods', currentMonth.toISOString()]
      })
    }
  })

  const handleSubmit = () => {
    if (!user) return
    
    mutation.mutate({
      userId: user.uid,
      date: selectedDate,
      time: selectedTime,
      moodScore,
      notes
    })
  }

  return (
    <PageTransition>
      <div className="space-y-6">
        <div className="glass-panel rounded-3xl bg-gradient-to-b from-background/80 to-background/40 backdrop-blur-xl">
          {/* Date & Time Selection */}
          <div className="flex items-center justify-between p-6 border-b border-foreground/5">
            <h2 className="text-xl font-semibold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
              New Entry
            </h2>
            <div className="flex gap-4">
              <Input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                startContent={<CalendarIcon className="h-4 w-4 text-default-400" />}
                classNames={{
                  base: "max-w-[180px]",
                  input: "text-small",
                  inputWrapper: "h-10 bg-foreground/5 backdrop-blur-lg hover:bg-foreground/10"
                }}
              />
              <Input
                type="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                startContent={<ClockIcon className="h-4 w-4 text-default-400" />}
                classNames={{
                  base: "max-w-[140px]",
                  input: "text-small",
                  inputWrapper: "h-10 bg-foreground/5 backdrop-blur-lg hover:bg-foreground/10"
                }}
              />
            </div>
          </div>

          {/* Mood Section */}
          <div className="border-b border-foreground/5 p-6">
            <div className="space-y-6">
              <div className="flex items-start justify-between">
                <div>
                  <h2 className="text-xl font-semibold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
                    Energy Level
                  </h2>
                  <p className="text-sm text-foreground/60 mt-1">How are you feeling right now?</p>
                </div>
                <div className="relative group">
                  <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-primary/20 to-blue-500/20 blur-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <div className={`
                    relative px-4 py-2 rounded-2xl
                    bg-gradient-to-r ${getMoodColor(moodScore)}
                    backdrop-blur-lg
                  `}>
                    <p className="text-2xl font-bold text-white">{moodScore}</p>
                    <p className="text-sm text-white/90 text-right">
                      {moodDescriptions[moodScore as keyof typeof moodDescriptions]}
                    </p>
                  </div>
                </div>
              </div>
　　 　 　 　
              <MoodSlider 
                value={moodScore} 
                onChange={setMoodScore}
                className="mt-6"
              />
            </div>
          </div>

          {/* Notes Section */}
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h2 className="text-xl font-semibold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
                  Notes
                </h2>
                <p className="text-sm text-foreground/60">What's contributing to your current state?</p>
              </div>
            </div>
            <Textarea
              placeholder="Describe your thoughts, activities, or any triggers..."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              minRows={3}
              classNames={{
                label: "text-foreground/70",
                input: "bg-background/50 text-foreground",
                inputWrapper: [
                  "bg-background/50",
                  "backdrop-blur-xl",
                  "hover:bg-background/60",
                  "group-data-[focused=true]:bg-background/70",
                  "!cursor-text",
                  "transition-all",
                  "border border-white/10"
                ].join(" ")
              }}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="grid grid-cols-2 gap-4">
          <Button
            className="h-14 bg-foreground/5 backdrop-blur-xl hover:bg-foreground/10 text-foreground rounded-2xl"
            onClick={() => navigate(-1)}
            size="lg"
          >
            Cancel
          </Button>
          <Button
            className="h-14 bg-gradient-to-r from-primary to-primary/80 hover:opacity-90 text-primary-foreground rounded-2xl"
            onClick={handleSubmit}
            isLoading={mutation.isPending}
            size="lg"
          >
            Save Entry
          </Button>
        </div>
      </div>
    </PageTransition>
  )
} 