import { Line } from 'react-chartjs-2'
import { type ChartData, type ChartOptions } from 'chart.js'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js'
import { useTheme } from '@/stores/theme-context'
import { motion } from 'framer-motion'
import { useRef, useState, useEffect } from 'react'

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

interface MoodChartProps {
  dates: string[]
  averageMoods: number[]
}

export function MoodChart({ dates, averageMoods }: MoodChartProps) {
  const { theme } = useTheme()
  const isDark = theme === 'dark'
  const chartRef = useRef<HTMLDivElement>(null)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  useEffect(() => {
    if (!isMobile || !chartRef.current) return

    // Find the last non-empty data point
    const lastEntryIndex = averageMoods.reduce((lastIndex, mood, currentIndex) => {
      return mood !== undefined ? currentIndex : lastIndex
    }, -1)
    
    if (lastEntryIndex <= 0) return // Don't scroll if last entry is at the start

    // Calculate scroll position based on the last entry
    const scrollRatio = lastEntryIndex / averageMoods.length
    const totalWidth = chartRef.current.scrollWidth - chartRef.current.clientWidth
    const scrollPosition = totalWidth * scrollRatio

    chartRef.current.scrollTo({
      left: scrollPosition,
      behavior: 'smooth'
    })
  }, [averageMoods, isMobile])

  // Theme-aware colors
  const colors = {
    primary: isDark ? '#22d3ee' : '#0ea5e9',
    background: isDark ? '#09090b' : '#ffffff',
    gridLines: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    text: isDark ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
    tooltipBackground: isDark ? 'rgba(15, 15, 23, 0.9)' : 'rgba(255, 255, 255, 0.9)',
    tooltipBorder: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
    gradientFrom: isDark ? 'rgba(34, 211, 238, 0.2)' : 'rgba(14, 165, 233, 0.2)',
    gradientTo: isDark ? 'rgba(34, 211, 238, 0)' : 'rgba(14, 165, 233, 0)',
    pointBackground: isDark ? '#22d3ee' : '#0284c7', // Darker blue for better visibility in light mode
    pointBorder: isDark ? '#22d3ee' : '#0284c7',
  }

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 750,
      easing: 'easeInOutQuart'
    },
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x'
    },
    scales: {
      y: {
        min: 0,
        max: 10,
        grid: {
          color: colors.gridLines,
        },
        ticks: {
          color: colors.text,
          padding: 8,
          font: {
            size: 12,
            family: 'Inter, system-ui, sans-serif',
          }
        },
        border: {
          display: false
        }
      },
      x: {
        grid: {
          color: colors.gridLines,
          display: false,
        },
        ticks: {
          color: colors.text,
          padding: 8,
          font: {
            size: 12,
            family: 'Inter, system-ui, sans-serif',
          },
          maxRotation: 0,
          autoSkip: false,
          callback: function(value, index) {
            const date = new Date(dates[index] + 'T00:00:00')
            return date.getDate()
          }
        },
        border: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: colors.tooltipBackground,
        titleColor: colors.text,
        bodyColor: isDark ? '#ffffff' : '#000000',
        borderColor: colors.tooltipBorder,
        borderWidth: 1,
        padding: 12,
        cornerRadius: 12,
        titleFont: {
          size: 14,
          family: 'Inter, system-ui, sans-serif',
          weight: 600
        },
        bodyFont: {
          size: 13,
          family: 'Inter, system-ui, sans-serif'
        },
        displayColors: false,
        callbacks: {
          title: (items) => {
            if (!items.length) return ''
            const date = new Date(items[0].label + 'T00:00:00')
            return date.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })
          },
          label: (item) => `Mood: ${item.formattedValue}`
        }
      }
    }
  }

  const data: ChartData<'line'> = {
    labels: dates,
    datasets: [{
      label: 'Mood Level',
      data: averageMoods,
      borderColor: colors.primary,
      borderWidth: 2.5,
      fill: true,
      backgroundColor: (context) => {
        if (!context.chart.chartArea) return colors.gradientFrom

        const { ctx, chartArea: { top, bottom } } = context.chart
        const gradientFill = ctx.createLinearGradient(0, top, 0, bottom)
        gradientFill.addColorStop(0, colors.gradientFrom)
        gradientFill.addColorStop(1, colors.gradientTo)
        return gradientFill
      },
      tension: 0.4,
      pointRadius: 3, // Show points by default for better visibility
      pointHoverRadius: 6,
      pointHitRadius: 30,
      pointBackgroundColor: colors.pointBackground,
      pointBorderColor: colors.pointBorder,
      pointHoverBackgroundColor: colors.background,
      pointHoverBorderColor: colors.pointBackground,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      spanGaps: true,
      showLine: true
    }]
  }

  if (!dates?.length || !averageMoods?.length) {
    return (
      <div className="flex h-full w-full items-center justify-center text-foreground/60">
        No data available for this period
      </div>
    )
  }

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      className="glass-morphism rounded-2xl p-4 h-[95%] flex flex-col overflow-hidden"
    >
      <div ref={chartRef} className="flex-1 min-h-0 overflow-x-auto">
        <div style={{ minWidth: '800px', height: '100%' }}>
          <Line options={chartOptions} data={data} />
        </div>
      </div>
    </motion.div>
  )
}