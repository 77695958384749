import { loadStripe } from '@stripe/stripe-js';
import { auth, db } from '@/lib/utils/firebase';
import { collection, doc, getDoc, setDoc, onSnapshot, updateDoc, serverTimestamp, DocumentSnapshot } from 'firebase/firestore';
import { toast } from 'react-toastify';

// Initialize Stripe
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

// Types
export interface SubscriptionStatus {
  isActive: boolean;
  periodEnd?: number;
  cancelAtPeriodEnd?: boolean;
}

interface CheckoutSessionData {
  sessionId: string;
  url?: string;
}

interface PortalSessionData {
  url: string;
}

// Check if we're in development mode
const isDevelopment = import.meta.env.DEV;

// Helper function to simulate async delay
const simulateDelay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const stripe = {
  // Get Stripe instance
  getInstance: async () => {
    return await stripePromise;
  },

  // Create checkout session
  createCheckoutSession: async (priceId: string): Promise<string> => {
    const user = auth.currentUser;
    if (!user) throw new Error('User must be logged in');

    if (isDevelopment) {
      // Show loading toast
      const toastId = toast.loading('Creating test subscription...');

      // In development, directly create a mock subscription
      const subscriptionRef = doc(db, 'users', user.uid, 'subscriptions', 'subscription');
      
      // Simulate a small delay to make it feel more realistic
      await simulateDelay(1500);
      
      await setDoc(subscriptionRef, {
        status: 'active',
        current_period_end: { seconds: Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60 }, // 30 days from now
        cancel_at_period_end: false,
      });

      // Update toast and reload
      toast.update(toastId, { 
        render: 'Subscription activated! (Development Mode)', 
        type: 'success', 
        isLoading: false,
        autoClose: 2000
      });
      
      // Reload after toast
      setTimeout(() => window.location.reload(), 2000);
      return 'Subscription activated (Development Mode)';
    }

    const checkoutSessionRef = doc(collection(db, 'users', user.uid, 'checkout_sessions'));
    
    // Create the checkout session
    await setDoc(checkoutSessionRef, {
      price: priceId,
      success_url: window.location.origin + '/settings',
      cancel_url: window.location.origin + '/settings',
      metadata: {
        userId: user.uid,
      },
    });

    // Wait for the CheckoutSession to get attached by the extension
    const unsubscribe = onSnapshot(checkoutSessionRef, async (snap: DocumentSnapshot) => {
      const data = snap.data() as CheckoutSessionData | undefined;
      if (data?.sessionId) {
        const stripe = await stripePromise;
        stripe?.redirectToCheckout({ sessionId: data.sessionId });
        unsubscribe();
      }
    });

    return 'Creating checkout session...';
  },

  // Get subscription status
  getSubscriptionStatus: async (): Promise<SubscriptionStatus> => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        return { isActive: false };
      }

      // Check if user has lifetime premium
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      const userData = userDoc.data();
      
      if (userData?.lifetimePremium === true) {
        return { isActive: true };
      }

      const subscriptionRef = doc(db, 'users', currentUser.uid, 'subscriptions', 'subscription');
      const subscriptionDoc = await getDoc(subscriptionRef);

      if (!subscriptionDoc.exists()) {
        return { isActive: false };
      }

      const subscription = subscriptionDoc.data();
      return {
        isActive: subscription.status === 'active',
        periodEnd: subscription.current_period_end?.seconds,
        cancelAtPeriodEnd: subscription.cancel_at_period_end
      };
    } catch (error) {
      console.error(error);
      return { isActive: false };
    }
  },

  // Create portal session
  createPortalSession: async (): Promise<string> => {
    const user = auth.currentUser;
    if (!user) throw new Error('User must be logged in');

    if (isDevelopment) {
      // Show loading toast
      const toastId = toast.loading('Updating subscription...');

      const subscriptionRef = doc(db, 'users', user.uid, 'subscriptions', 'subscription');
      const subscriptionDoc = await getDoc(subscriptionRef);

      // Simulate a small delay
      await simulateDelay(1500);

      if (subscriptionDoc.exists()) {
        // Toggle the subscription status
        const subscription = subscriptionDoc.data();
        const newStatus = !subscription.cancel_at_period_end;
        
        await setDoc(subscriptionRef, {
          ...subscription,
          cancel_at_period_end: newStatus
        });

        // Update toast
        toast.update(toastId, { 
          render: newStatus ? 'Subscription will be cancelled at period end' : 'Subscription will auto-renew', 
          type: 'success', 
          isLoading: false,
          autoClose: 2000
        });
      }

      // Reload after toast
      setTimeout(() => window.location.reload(), 2000);
      return 'Subscription updated (Development Mode)';
    }

    const portalSessionRef = doc(collection(db, 'users', user.uid, 'portal_sessions'));
    
    // Create the portal session
    await setDoc(portalSessionRef, {
      return_url: window.location.origin + '/settings',
    });

    // Wait for the PortalSession to get attached by the extension
    const unsubscribe = onSnapshot(portalSessionRef, async (snap: DocumentSnapshot) => {
      const data = snap.data() as PortalSessionData | undefined;
      if (data?.url) {
        window.location.assign(data.url);
        unsubscribe();
      }
    });

    return 'Creating portal session...';
  },

  // Set lifetime premium status
  setLifetimePremium: async (userId: string, enabled: boolean = true) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        lifetimePremium: enabled,
        lifetimePremiumUpdatedAt: serverTimestamp()
      });
      return true;
    } catch (error) {
      console.error('Error setting lifetime premium status:', error);
      throw error;
    }
  }
};