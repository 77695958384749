import { useQuery, useQueryClient } from '@tanstack/react-query'
import { collection, query, getDocs } from 'firebase/firestore'
import { db } from '@/lib/utils/firebase'
import { useAuth } from '@/stores/auth-context'
import type { MoodEntry, MoodStats } from '@/types/mood'

export function useMoodStats() {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ['moodStats'],
    queryFn: async (): Promise<MoodStats> => {
      if (!user) throw new Error('User not authenticated')

      const moodsRef = collection(db, 'users', user.uid, 'moods')
      const querySnapshot = await getDocs(moodsRef)
      
      const entries = querySnapshot.docs.map((doc) => {
        const data = doc.data()
        return {
          id: doc.id,
          ...data,
          timestamp: data.timestamp,
          createdAt: data.createdAt
        } as MoodEntry
      })

      const dates = new Set(entries.map(entry => 
        entry.timestamp.toDate().toISOString().split('T')[0]
      ))
      
      let currentStreak = 0
      let currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)

      while (dates.has(currentDate.toISOString().split('T')[0])) {
        currentStreak++
        currentDate.setDate(currentDate.getDate() - 1)
      }

      const thirtyDaysAgo = new Date()
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

      const recentEntries = entries.filter(entry => 
        entry.timestamp.toDate() >= thirtyDaysAgo
      )

      const thirtyDayAverage = recentEntries.length > 0
        ? recentEntries.reduce((acc, entry) => acc + entry.moodScore, 0) / recentEntries.length
        : 0

      const moodScores = entries.map(entry => entry.moodScore)
      const lastEntry = entries.length > 0 
        ? entries.reduce((latest, entry) => 
            entry.timestamp.toDate() > latest.timestamp.toDate() ? entry : latest
          ).timestamp.toDate()
        : null

      return {
        thirtyDayAverage,
        totalEntries: entries.length,
        highestMood: Math.max(...moodScores, 0),
        lowestMood: Math.min(...moodScores, 0),
        currentStreak,
        lastEntry
      }
    },
    refetchInterval: 30000, // Refetch every 30 seconds
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60, // Consider data stale after 1 minute
    select: (data) => {
      // This will trigger related query invalidations when the data changes
      queryClient.invalidateQueries({ queryKey: ['monthlyMoods'] })
      queryClient.invalidateQueries({ queryKey: ['dailyMoods'] })
      return data
    }
  })
}