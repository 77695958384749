import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from '@nextui-org/react'
import { EnvelopeIcon, KeyIcon, UserIcon } from '@heroicons/react/24/outline'
import { createUserWithEmailAndPassword, updateProfile, AuthError } from 'firebase/auth'
import { auth } from '@/lib/utils/firebase'
import { updateUserProfile } from '@/services/user'

interface SignUpFormProps {
  email: string
  password: string
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.FormEvent) => void
  error: string | null
  isLoading: boolean
}

export function SignUpForm({ 
  email, 
  password, 
  onChangeEmail, 
  onChangePassword, 
  onSubmit, 
  error, 
  isLoading 
}: SignUpFormProps) {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(e)
    setNameError('')
    
    // Validate password
    if (password.length < 6) {
      setNameError('Password must be at least 6 characters')
      return
    }

    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password)
      const trimmedName = name.trim()
      
      // Only update profile if name is provided
      if (trimmedName) {
        try {
          await updateProfile(user, { displayName: trimmedName })
          await updateUserProfile(user.uid, { displayName: trimmedName })
        } catch (profileError) {
          console.error('Failed to set profile data:', profileError)
        }
      }
      navigate('/home')
    } catch (error) {
      const authError = error as AuthError
      switch (authError.code) {
        case 'auth/email-already-in-use':
          setNameError('This email is already registered')
          break
        case 'auth/invalid-email':
          setNameError('Invalid email address')
          break
        case 'auth/operation-not-allowed':
          setNameError('Email/password accounts are not enabled')
          break
        default:
          setNameError('Failed to create account')
          console.error('Sign up error:', error)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4 w-full">
      <Input
        label="Name (optional)"
        placeholder="Enter your name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        startContent={<UserIcon className="h-4 w-4 text-foreground/60" />}
        classNames={{
          label: "text-foreground/70",
          input: "text-foreground",
          inputWrapper: "bg-foreground/5 h-12"
        }}
      />
      <Input
        type="email"
        label="Email"
        placeholder="Enter your email"
        value={email}
        onChange={onChangeEmail}
        startContent={<EnvelopeIcon className="w-4 h-4 text-foreground/60" />}
        classNames={{
          label: "text-foreground/70",
          input: "text-foreground",
          inputWrapper: "bg-foreground/5 h-12"
        }}
      />
      <Input
        type="password"
        label="Password"
        placeholder="Enter your password"
        value={password}
        onChange={onChangePassword}
        startContent={<KeyIcon className="w-4 h-4 text-foreground/60" />}
        classNames={{
          label: "text-foreground/70",
          input: "text-foreground",
          inputWrapper: "bg-foreground/5 h-12"
        }}
      />
      {(error || nameError) && (
        <p className="text-danger text-sm">{error || nameError}</p>
      )}
      <Button
        type="submit"
        className="w-full h-12 bg-primary/10 text-primary rounded-xl backdrop-blur-xl hover:bg-primary/20 transition-colors"
        isLoading={isLoading}
      >
        Sign Up
      </Button>
    </form>
  )
}