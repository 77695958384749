import { Link, useLocation, useNavigate } from 'react-router-dom'
import { 
  HomeIcon, PlusCircleIcon, CalendarIcon, 
  UserCircleIcon, Cog6ToothIcon 
} from '@heroicons/react/24/outline'

const getTodayLocalDate = () => {
  const today = new Date()
  return today.toLocaleDateString('en-CA') // Returns YYYY-MM-DD in local timezone
}

const navItems = [
  { label: 'Home', path: '/home', icon: HomeIcon },
  { label: 'New', path: '/input', icon: PlusCircleIcon },
  { label: 'Daily', path: '/day', icon: CalendarIcon, isDailyTab: true },
  { label: 'Profile', path: '/profile', icon: UserCircleIcon },
  { label: 'Settings', path: '/settings', icon: Cog6ToothIcon }
]

export function MobileNavigation() {
  const location = useLocation()
  const navigate = useNavigate()
  const basePath = location.pathname.split('?')[0]

  const handleNavClick = (path: string, isDailyTab?: boolean) => {
    if (isDailyTab) {
      navigate(`/day?date=${getTodayLocalDate()}`)
    } else {
      navigate(path)
    }
  }

  return (
    <nav className="fixed bottom-0 left-0 right-0 z-50 h-nav mobile-safe-area bg-background/80 backdrop-blur-xl border-t border-foreground/10">
      <div className="flex h-full items-center justify-around px-4">
        {navItems.map(({ label, path, icon: Icon, isDailyTab }) => {
          const itemBasePath = path.split('?')[0]
          return (
            <button
              key={path}
              onClick={() => handleNavClick(path, isDailyTab)}
              className={`nav-item touch-target
                ${basePath === itemBasePath ? 'active' : ''}`}
            >
              <Icon className="h-6 w-6" />
              <span className="text-xs">{label}</span>
            </button>
          )
        })}
      </div>
    </nav>
  )
}
