import { useQuery } from '@tanstack/react-query'
import { db } from '@/lib/utils/firebase'
import { collection, query, where, orderBy, getDocs, Timestamp } from 'firebase/firestore'
import { useAuth } from '@/stores/auth-context'
import type { MoodEntry } from '@/types/mood'

export function useDailyMoods(date: string) {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['dailyMoods', date],
    queryFn: async (): Promise<MoodEntry[]> => {
      if (!user) throw new Error('User not authenticated')

      console.log('Fetching daily moods:', { date, userId: user.uid })

      // Parse the date in local timezone
      const [year, month, day] = date.split('-').map(Number)
      
      // Create start and end dates in local timezone
      const startDate = new Date(year, month - 1, day)
      startDate.setHours(0, 0, 0, 0)
      
      const endDate = new Date(year, month - 1, day)
      endDate.setHours(23, 59, 59, 999)

      console.log('Query time range:', {
        input: date,
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        startLocal: startDate.toString(),
        endLocal: endDate.toString()
      })

      const moodsRef = collection(db, 'users', user.uid, 'moods')
      const q = query(
        moodsRef,
        where('timestamp', '>=', Timestamp.fromDate(startDate)),
        where('timestamp', '<=', Timestamp.fromDate(endDate)),
        orderBy('timestamp', 'asc')
      )

      try {
        const querySnapshot = await getDocs(q)
        console.log('Daily moods query result:', {
          count: querySnapshot.size,
          date,
          hasData: querySnapshot.size > 0
        })

        return querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().timestamp.toDate().toISOString().split('T')[0],
          time: doc.data().timestamp.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
        })) as MoodEntry[]
      } catch (error) {
        console.error('Error fetching daily moods:', error)
        throw error
      }
    },
    enabled: !!user && !!date,
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: 2
  })
}