import { MobileHeader } from '@/components/navigation/mobile-header'
import { MobileNavigation } from '@/components/navigation/mobile-navigation'
import React from 'react'
import { Outlet } from 'react-router-dom'

export function MobileLayout() {
  return (
    <div className="flex flex-col min-h-screen bg-background">
      <MobileHeader />
      
      <main className="flex-1 overflow-y-auto scrollbar-hide pt-header pb-nav">
        <div className="mobile-container">
          <Outlet />
        </div>
      </main>

      <MobileNavigation />
    </div>
  )
}