import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '@/stores/auth-context'

export function MobileHeader() {
  const { user } = useAuth()
  const navigate = useNavigate()

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (user) navigate('/home')
    else navigate('/auth')
  }

  return (
    <header className="fixed left-0 right-0 top-0 z-50 h-header bg-background/80 backdrop-blur-xl border-b border-foreground/10">
      <div className="flex h-full items-center px-4">
        <a onClick={handleLogoClick} className="flex items-center gap-2 cursor-pointer">
          <div className="relative h-8 w-8 rounded-xl">
            <div className="absolute inset-0 rounded-xl bg-gradient-to-tr from-primary to-secondary opacity-50" />
            <div className="absolute inset-0.5 rounded-[10px] bg-background/90 backdrop-blur-sm" />
          </div>
          <span className="text-gradient text-xl font-bold">moodrecall</span>
        </a>
      </div>
    </header>
  )
}