import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input } from '@nextui-org/react'
import { EnvelopeIcon, KeyIcon } from '@heroicons/react/24/outline'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '@/lib/utils/firebase'

interface SignInFormProps {
  email: string
  password: string
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.FormEvent) => void
  error: string | null
  isLoading: boolean
}

export function SignInForm({ 
  email, 
  password, 
  onChangeEmail, 
  onChangePassword, 
  onSubmit, 
  error, 
  isLoading 
}: SignInFormProps) {
  return (
    <form onSubmit={onSubmit} className="space-y-4 w-full">
      <Input
        type="email"
        label="Email"
        placeholder="Enter your email"
        value={email}
        onChange={onChangeEmail}
        startContent={<EnvelopeIcon className="w-4 h-4 text-foreground/60" />}
        classNames={{
          label: "text-foreground/70",
          input: "text-foreground",
          inputWrapper: "bg-foreground/5 h-12"
        }}
        isRequired
      />
      <Input
        type="password"
        label="Password"
        placeholder="Enter your password"
        value={password}
        onChange={onChangePassword}
        startContent={<KeyIcon className="w-4 h-4 text-foreground/60" />}
        classNames={{
          label: "text-foreground/70",
          input: "text-foreground",
          inputWrapper: "bg-foreground/5 h-12"
        }}
        isRequired
      />
      {error && (
        <p className="text-center text-sm text-danger">{error}</p>
      )}
      <Button
        type="submit"
        className="w-full bg-primary/10 text-primary h-12"
        isLoading={isLoading}
      >
        Sign In
      </Button>
    </form>
  )
}