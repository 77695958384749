import { useEffect, useState } from 'react'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '@/lib/utils/firebase'
import { useAuth } from '@/stores/auth-context'

export function useNeedsMigration() {
  const [needsMigration, setNeedsMigration] = useState<boolean | null>(null)
  const [isChecking, setIsChecking] = useState(true)
  const { user } = useAuth()

  useEffect(() => {
    async function checkMigrationStatus() {
      if (!user) {
        setNeedsMigration(null)
        setIsChecking(false)
        return
      }

      try {
        // Check for any entries in the old format
        const oldMoodsQuery = query(
          collection(db, 'moods'),
          where('userId', '==', user.uid)
        )
        const oldMoodsSnapshot = await getDocs(oldMoodsQuery)

        // Check for any entries in the new format
        const newMoodsQuery = query(
          collection(db, `users/${user.uid}/moods`)
        )
        const newMoodsSnapshot = await getDocs(newMoodsQuery)

        // If there are old entries but no new entries, migration is needed
        setNeedsMigration(
          !oldMoodsSnapshot.empty && newMoodsSnapshot.empty
        )
      } catch (error) {
        console.error('Error checking migration status:', error)
        setNeedsMigration(false)
      } finally {
        setIsChecking(false)
      }
    }

    checkMigrationStatus()
  }, [user])

  return { needsMigration, isChecking }
}
