import { useState } from 'react'
import { Card, CardBody, Button, Input, Textarea, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react'
import type { MoodEntry } from '@/types/mood'
import { updateMoodEntry, deleteMoodEntry } from '@/services/mood'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MoodSlider } from './mood-slider'
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import { motion } from 'framer-motion'
import { parseAmPmTo24Hour } from '@/utils/time'

const moodDescriptions = {
  0: 'Severe Depression - Very low energy, withdrawn',
  1: 'Deep Depression - Low energy, isolated',
  2: 'Moderate Depression - Limited energy',
  3: 'Mild Depression - Below normal energy',
  4: 'Slightly Low - Just below baseline',
  5: 'Neutral - Balanced energy level',
  6: 'Slightly Elevated - More active than usual',
  7: 'Elevated - High energy, very active',
  8: 'Highly Elevated - Excessive energy',
  9: 'Hypomania - Racing thoughts, overactive',
  10: 'Mania - Extreme energy, potentially risky'
}

function getMoodColor(score: number) {
  if (score <= 2) return 'from-red-500/80 to-red-700/60' // Deep depression
  if (score <= 4) return 'from-orange-500/80 to-orange-700/60' // Mild depression
  if (score === 5) return 'from-blue-500/80 to-blue-700/60' // Neutral
  if (score <= 7) return 'from-green-500/80 to-green-700/60' // Elevated
  return 'from-purple-500/80 to-purple-700/60' // Mania
}

interface EditForm {
  time?: string
  moodScore?: number
  notes?: string
}

interface MoodEntryListProps {
  entries: MoodEntry[]
  date: string
}

export function MoodEntryList({ entries, date }: MoodEntryListProps) {
  const [editingId, setEditingId] = useState<string | null>(null)
  const [editForm, setEditForm] = useState<EditForm>({})
  const [deleteModalEntry, setDeleteModalEntry] = useState<MoodEntry | null>(null)
  const queryClient = useQueryClient()
  
  const updateMutation = useMutation({
    mutationFn: ({ id, updates }: { id: string, updates: any }) => {
      console.log('Updating entry:', id, updates)
      return updateMoodEntry(id, updates)
    },
    onSuccess: () => {
      console.log('Update successful')
      queryClient.invalidateQueries({ queryKey: ['dailyMoods', date] })
      queryClient.invalidateQueries({ queryKey: ['monthlyMoods'] })
      setEditingId(null)
      setEditForm({})
    },
    onError: (error) => {
      console.error('Update failed:', error)
    }
  })

  const deleteMutation = useMutation({
    mutationFn: (id: string) => {
      return deleteMoodEntry(id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dailyMoods', date] })
      queryClient.invalidateQueries({ queryKey: ['monthlyMoods'] })
    },
    onError: (error) => {
      console.error('Delete failed:', error)
    }
  })

  function handleUpdate(entry: MoodEntry) {
    console.log('Edit form state:', editForm)
    console.log('Original entry:', entry)

    if (!Object.keys(editForm).length) {
      setEditingId(null)
      return
    }

    const updates: Partial<MoodEntry> = {}
    
    // Only include fields that have actually changed
    if (editForm.time && editForm.time !== entry.time) {
      updates.time = editForm.time
    }
    if (editForm.moodScore !== undefined && editForm.moodScore !== entry.moodScore) {
      updates.moodScore = editForm.moodScore
    }
    if (editForm.notes !== undefined && editForm.notes !== entry.notes) {
      updates.notes = editForm.notes
    }

    if (Object.keys(updates).length === 0) {
      setEditingId(null)
      return
    }

    console.log('Sending updates:', updates)
    updateMutation.mutate({ id: entry.id, updates })
  }

  const handleTimeChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const time24h = parseAmPmTo24Hour(e.target.value)
    setEditForm((prev: EditForm) => ({ ...prev, time: time24h }))
  }

  const handleMoodScoreChange = (e: React.FocusEvent<HTMLInputElement>) => {
    setEditForm((prev: EditForm) => ({ 
      ...prev, 
      moodScore: parseInt(e.target.value) 
    }))
  }

  const handleNotesChange = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setEditForm((prev: EditForm) => ({ ...prev, notes: e.target.value }))
  }

  const handleDeleteClick = (entry: MoodEntry) => {
    setDeleteModalEntry(entry)
  }

  const handleDeleteConfirm = () => {
    if (deleteModalEntry) {
      deleteMutation.mutate(deleteModalEntry.id)
      setDeleteModalEntry(null)
    }
  }

  return (
    <div className="space-y-4">
      {entries.map((entry) => (
        <motion.div 
          key={entry.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="glass-morphism rounded-2xl w-full"
        >
          {editingId === entry.id ? (
            <div className="p-6 space-y-6">
              <h2 className="text-xl font-semibold mb-6 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                Edit Entry
              </h2>
              <div className="space-y-4">
                <motion.div
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 }}
                >
                  <Input
                    type="time"
                    label="Time"
                    defaultValue={parseAmPmTo24Hour(entry.time)}
                    className="max-w-[200px]"
                    onBlur={(event) => handleTimeChange(event as React.FocusEvent<HTMLInputElement>)}
                    aria-label="Entry time"
                    classNames={{
                      label: "text-foreground/70",
                      input: "bg-background/50 text-foreground",
                      inputWrapper: [
                        "bg-background/50",
                        "backdrop-blur-xl",
                        "hover:bg-background/60",
                        "group-data-[focused=true]:bg-background/70",
                        "!cursor-text",
                        "transition-all",
                        "border border-white/10"
                      ].join(" ")
                    }}
                  />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <div className="w-full">
                    <div className="flex items-start justify-between mb-4">
                      <div>
                        <p className="text-foreground/70 text-sm mb-2">
                          Energy Level
                        </p>
                      </div>
                      <div className="relative group">
                        <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-primary/20 to-blue-500/20 blur-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                        <div className={`
                          relative px-4 py-2 rounded-2xl
                          bg-gradient-to-r ${getMoodColor(editForm.moodScore ?? entry.moodScore)}
                          backdrop-blur-lg
                        `}>
                          <p className="text-2xl font-bold text-white">{editForm.moodScore ?? entry.moodScore}</p>
                          <p className="text-sm text-white/90 text-right">
                            {moodDescriptions[(editForm.moodScore ?? entry.moodScore) as keyof typeof moodDescriptions]}
                          </p>
                        </div>
                      </div>
                    </div>
                    <MoodSlider
                      value={editForm.moodScore ?? entry.moodScore}
                      onChange={(value) => setEditForm(prev => ({ ...prev, moodScore: value }))}
                      className="mt-6"
                    />
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  <Textarea
                    label="Notes"
                    defaultValue={entry.notes}
                    onBlur={(event) => handleNotesChange(event as React.FocusEvent<HTMLTextAreaElement>)}
                    aria-label="Entry notes"
                    classNames={{
                      label: "text-foreground/70",
                      input: "bg-background/50 text-foreground",
                      inputWrapper: [
                        "bg-background/50",
                        "backdrop-blur-xl",
                        "hover:bg-background/60",
                        "group-data-[focused=true]:bg-background/70",
                        "!cursor-text",
                        "transition-all",
                        "border border-white/10"
                      ].join(" ")
                    }}
                  />
                </motion.div>
              </div>
              <div className="flex justify-end gap-2 pt-2">
                <Button 
                  variant="flat"
                  className="bg-danger/10 text-danger hover:opacity-90 transition-all duration-200"
                  onClick={() => {
                    setEditingId(null)
                    setEditForm({})
                  }}
                  aria-label="Cancel editing"
                >
                  Cancel
                </Button>
                <Button 
                  className="bg-gradient-to-r from-primary to-secondary text-background font-medium
                            hover:opacity-90 transition-all duration-200"
                  onClick={() => handleUpdate(entry)}
                  aria-label="Save changes"
                >
                  Save Changes
                </Button>
              </div>
            </div>
          ) : (
            <div className="p-4 flex items-center justify-between">
              <div>
                <p className="text-lg font-semibold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent" role="text">
                  {entry.time}
                </p>
                <p className="text-foreground/70" role="text">
                  {entry.notes || 'No notes'}
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <div 
                  className={[
                    "flex h-10 w-10 items-center justify-center rounded-xl",
                    "bg-background/50 backdrop-blur-xl border border-white/10"
                  ].join(" ")}
                  role="status"
                  aria-label={`Mood score: ${entry.moodScore}`}
                >
                  <span className="text-base font-bold bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                    {entry.moodScore}
                  </span>
                </div>
                <Button
                  isIconOnly
                  className={[
                    "w-10 h-10 rounded-xl",
                    "bg-background/50 backdrop-blur-xl",
                    "hover:bg-background/60",
                    "transition-all",
                    "border border-white/10"
                  ].join(" ")}
                  variant="light"
                  onClick={() => {
                    setEditingId(entry.id)
                    setEditForm({
                      time: entry.time,
                      moodScore: entry.moodScore,
                      notes: entry.notes
                    })
                  }}
                  aria-label={`Edit entry from ${entry.time}`}
                >
                  <PencilIcon className="h-5 w-5 text-foreground/70" />
                </Button>
                <Button
                  isIconOnly
                  className={[
                    "w-10 h-10 rounded-xl",
                    "bg-background/50 backdrop-blur-xl",
                    "hover:bg-danger/20",
                    "transition-all",
                    "border border-white/10"
                  ].join(" ")}
                  variant="light"
                  onClick={() => handleDeleteClick(entry)}
                  aria-label={`Delete entry from ${entry.time}`}
                >
                  <TrashIcon className="h-5 w-5 text-danger" />
                </Button>
              </div>
            </div>
          )}
        </motion.div>
      ))}
      <Modal 
        isOpen={deleteModalEntry !== null} 
        onClose={() => setDeleteModalEntry(null)}
        classNames={{
          base: "glass-morphism",
          header: "border-b border-white/10",
          body: "py-6",
          footer: "border-t border-white/10"
        }}
      >
        <ModalContent>
          <ModalHeader>
            <h3 className="text-xl font-semibold">Delete Entry</h3>
          </ModalHeader>
          <ModalBody>
            <p className="text-foreground/70">
              Are you sure you want to delete this entry from {deleteModalEntry?.time}? This action cannot be undone.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="light"
              onPress={() => setDeleteModalEntry(null)}
            >
              Cancel
            </Button>
            <Button
              color="danger"
              variant="flat"
              onPress={handleDeleteConfirm}
            >
              Delete Entry
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}