import { db } from '@/lib/utils/firebase'
import { 
  collection, 
  getDocs, 
  query, 
  writeBatch,
  where, 
  doc,
  Timestamp
} from 'firebase/firestore'

export async function migrateToUserSubcollections(currentUserId: string) {
  try {
    if (!currentUserId) {
      throw new Error('User ID is required for migration')
    }

    // Get all mood entries for the current user
    const moodsRef = collection(db, 'moods')
    const q = query(moodsRef, where('userId', '==', currentUserId))
    const moodsSnapshot = await getDocs(q)
    
    if (moodsSnapshot.empty) {
      console.log('No entries found to migrate')
      return { success: true, message: 'No entries to migrate' }
    }

    // Create batch operations
    const batch = writeBatch(db)
    let operationCount = 0
    const BATCH_LIMIT = 500

    // Create user profile document
    const userProfileRef = doc(db, 'users', currentUserId, 'profile', 'data')
    batch.set(userProfileRef, {
      createdAt: Timestamp.fromDate(new Date())
    })
    operationCount++

    // Move mood entries to user subcollection
    for (const docSnapshot of moodsSnapshot.docs) {
      const data = docSnapshot.data()
      const { userId, ...entryData } = data

      // Ensure timestamp is a Firestore timestamp
      if (!(entryData.timestamp instanceof Timestamp)) {
        const date = new Date(data.date + 'T' + (data.time || '00:00:00'))
        entryData.timestamp = Timestamp.fromDate(date)
      }

      const newMoodRef = doc(db, 'users', currentUserId, 'moods', docSnapshot.id)
      batch.set(newMoodRef, entryData)
      
      // Delete old entry
      const oldMoodRef = doc(db, 'moods', docSnapshot.id)
      batch.delete(oldMoodRef)

      operationCount += 2 // One set + one delete

      // If we hit the batch limit, commit and start a new batch
      if (operationCount >= BATCH_LIMIT) {
        await batch.commit()
        operationCount = 0
      }
    }

    // Commit any remaining operations
    if (operationCount > 0) {
      await batch.commit()
    }

    console.log('Migration completed successfully')
    return { success: true, message: `Migrated ${moodsSnapshot.size} entries` }
  } catch (error) {
    console.error('Migration failed:', error)
    return { success: false, error }
  }
}
