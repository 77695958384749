import { Slider } from '@nextui-org/react'

interface MoodSliderProps {
  value: number
  onChange: (value: number) => void
  className?: string
}

export function MoodSlider({ 
  value, 
  onChange, 
  className = ""
}: MoodSliderProps) {
  // Calculate background gradient based on value
  const gradientStyle = {
    background: `linear-gradient(to bottom, 
      rgb(255, 20, 147), 
      rgba(255, 255, 255, 0.1) ${50}%, 
      rgb(6, 182, 212))`
  }

  return (
    <div className={`relative flex items-center gap-6 ${className}`}>
      {/* Left Labels */}
      <div className="flex flex-col justify-between h-[200px] text-sm">
        <span className="font-medium text-pink-500">Mania</span>
        <span className="text-foreground/60">Neutral</span>
        <span className="font-medium text-cyan-500">Depression</span>
      </div>

      <div className="relative flex-1 h-[200px]">
        {/* Background Track */}
        <div 
          className="absolute left-1/2 -translate-x-1/2 h-full w-3 rounded-full"
          style={gradientStyle}
          aria-hidden="true"
        />
        
        <Slider
          size="lg"
          step={1}
          maxValue={10}
          minValue={0}
          value={value}
          onChange={(value) => onChange(Number(value))}
          orientation="vertical"
          defaultValue={5}
          aria-label="Mood Level"
          className="h-full"
          classNames={{
            base: "max-w-md mx-auto px-4",
            track: [
              "w-3",
              "bg-transparent",
              "border-0",
              "!border-transparent"
            ].join(" "),
            trackWrapper: "w-6 bg-transparent",
            filler: [
              "bg-transparent",
              "border-0",
              "!border-transparent"
            ].join(" "),
            thumb: [
              "bg-background",
              "border-4",
              "border-foreground/20",
              "transition-transform",
              "duration-200",
              "hover:scale-110",
              "!h-8",
              "!w-8",
              "rounded-full",
              "shadow-lg",
              "z-50"
            ].join(" ")
          }}
        />
      </div>

      {/* Right Labels */}
      <div className="flex flex-col justify-between h-[200px] text-sm text-foreground/60">
        <span aria-hidden="true">10</span>
        <span aria-hidden="true">5</span>
        <span aria-hidden="true">0</span>
      </div>
    </div>
  )
}