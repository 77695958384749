import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Spinner } from '@nextui-org/react'
import { 
  ChevronLeftIcon, 
  ChevronRightIcon,
  PlusCircleIcon,
  CalendarIcon,
  ChartBarIcon,
  FireIcon
} from '@heroicons/react/24/outline'
import { PageTransition } from '@/components/ui/page-transition'
import { MoodChart } from '@/components/mood/mood-chart'
import { LoadingTransition } from '@/components/ui/loading-transition'
import { useMoodStats } from '@/hooks/use-mood-stats'
import { useMoodData } from '@/hooks/use-mood-data'
import { useNeedsMigration } from '@/hooks/use-needs-migration'
import { MigrationPrompt } from '@/components/migration/migration-prompt'
import { PageContainer } from '@/components/layout/page-container'

export function HomePage() {
  const navigate = useNavigate()
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const { data: stats } = useMoodStats()
  const { data: monthlyData, isLoading } = useMoodData(currentMonth)
  const { needsMigration } = useNeedsMigration()
  const [showMigrationPrompt, setShowMigrationPrompt] = useState(false)
  
  const monthYear = currentMonth.toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric'
  })
  
  console.log('Monthly Data:', monthlyData)
  
  const chartDates = monthlyData?.dates || []
  const chartMoods = monthlyData?.averageMoods || []
  
  useEffect(() => {
    if (needsMigration) {
      setShowMigrationPrompt(true)
    }
  }, [needsMigration])

  return (
    <PageTransition>
      <div className="flex flex-col h-[calc(100vh-var(--header-height)-var(--mobile-nav-height))] overflow-hidden">
        <div className="flex flex-col h-full space-y-4 p-2">
          {/* Quick Stats - Modern cards with gradients */}
          <div className="grid grid-cols-2 gap-4">
            <div className="glass-panel rounded-3xl p-4 bg-gradient-to-br from-primary/10 to-primary/5 backdrop-blur-xl">
              <p className="text-sm text-foreground/60 font-medium">30 Day Average</p>
              <div className="flex items-end justify-between mt-1">
                <p className="text-3xl font-bold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
                  {stats?.thirtyDayAverage?.toFixed(1) || '-'}
                </p>
                <div className="h-8 w-8 rounded-xl bg-primary/10 flex items-center justify-center">
                  <ChartBarIcon className="h-5 w-5 text-primary" />
                </div>
              </div>
            </div>
            
            <div className="glass-panel rounded-3xl p-4 bg-gradient-to-br from-blue-500/10 to-blue-500/5 backdrop-blur-xl">
              <p className="text-sm text-foreground/60 font-medium">Current Streak</p>
              <div className="flex items-end justify-between mt-1">
                <p className="text-3xl font-bold bg-gradient-to-r from-blue-500 to-blue-500/60 bg-clip-text text-transparent">
                  {stats?.currentStreak || 0}
                  <span className="text-lg ml-1">{stats?.currentStreak === 1 ? 'day' : 'days'}</span>
                </p>
                <div className="h-8 w-8 rounded-xl bg-blue-500/10 flex items-center justify-center">
                  <FireIcon className="h-5 w-5 text-blue-500" />
                </div>
              </div>
            </div>
          </div>

          {/* Chart Section - Modern glass panel */}
          <div className="glass-panel rounded-3xl flex flex-col flex-1 min-h-0 bg-gradient-to-b from-background/80 to-background/40 backdrop-blur-xl">
            <div className="flex items-center justify-between p-4 border-b border-foreground/5">
              <h2 className="text-xl font-semibold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
                {monthYear}
              </h2>
              <div className="flex gap-2">
                <Button
                  isIconOnly
                  variant="light"
                  onClick={() => setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1))}
                  className="w-10 h-10 rounded-xl bg-foreground/5 hover:bg-foreground/10"
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </Button>
                <Button
                  isIconOnly
                  variant="light"
                  onClick={() => setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1))}
                  isDisabled={currentMonth >= new Date()}
                  className="w-10 h-10 rounded-xl bg-foreground/5 hover:bg-foreground/10"
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </Button>
              </div>
            </div>

            <div className="flex-1 min-h-0 p-4">
              {isLoading ? (
                <div className="flex h-full items-center justify-center">
                  <Spinner size="lg" color="primary" />
                </div>
              ) : (
                <MoodChart 
                  dates={chartDates} 
                  averageMoods={chartMoods} 
                />
              )}
            </div>
          </div>

          {/* Action Buttons - Modern floating buttons */}
          <div className="grid grid-cols-2 gap-4">
            <Button
              className="h-14 bg-gradient-to-r from-primary to-primary/80 hover:opacity-90 text-primary-foreground rounded-2xl font-medium"
              startContent={<PlusCircleIcon className="h-5 w-5" />}
              onClick={() => navigate('/input')}
              size="lg"
            >
              New Entry
            </Button>
            <Button
              className="h-14 bg-foreground/5 backdrop-blur-xl hover:bg-foreground/10 text-foreground rounded-2xl font-medium"
              startContent={<CalendarIcon className="h-5 w-5" />}
              onClick={() => navigate('/day')}
              size="lg"
            >
              View Day
            </Button>
          </div>
        </div>
      </div>
      <MigrationPrompt 
        isOpen={showMigrationPrompt} 
        onClose={() => setShowMigrationPrompt(false)} 
      />
    </PageTransition>
  )
}

interface StatsCardProps {
  title: string
  value: string | number
  icon: React.ReactNode
}

function StatsCard({ title, value, icon }: StatsCardProps) {
  return (
    <div className="flex items-center gap-3 rounded-lg bg-[#1A1A1A] p-4">
      <div className="rounded-full bg-cyan-500/10 p-2 text-cyan-400">
        {icon}
      </div>
      <div>
        <p className="text-xs text-gray-400">{title}</p>
        <p className="text-lg font-semibold text-white">{value}</p>
      </div>
    </div>
  )
}