import { useState, useEffect } from 'react'
import { User, Button, Modal, ModalContent, Input } from '@nextui-org/react'
import { CameraIcon } from '@heroicons/react/24/outline'
import { updateProfile } from 'firebase/auth'
import { auth } from '@/lib/utils/firebase'
import { updateUserProfile } from '@/services/user'
import { useAuth } from '@/stores/auth-context'

interface UserAvatarProps {
  email: string
  displayName?: string | null
  photoURL?: string | null
  onUpdate?: () => void
}

function getInitials(name: string | null | undefined): string {
  if (!name) return 'A'
  
  // Split the name into words and filter out empty strings
  const words = name.trim().split(/\s+/).filter(word => word.length > 0)
  
  if (words.length === 0) return 'A'
  if (words.length === 1) return words[0].substring(0, 2).toUpperCase()
  
  // Get first letter of first word and first letter of last word
  return (words[0][0] + words[words.length - 1][0]).toUpperCase()
}

export function UserAvatar({ email, displayName, photoURL, onUpdate }: UserAvatarProps) {
  const { refreshUser } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [name, setName] = useState(displayName || '')
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState('')

  // Keep local state in sync with props
  useEffect(() => {
    setName(displayName || '')
  }, [displayName])

  const handleUpdateProfile = async () => {
    if (!auth.currentUser) return
    setIsUpdating(true)
    setError('')

    try {
      const trimmedName = name.trim()
      if (trimmedName !== displayName) {
        // Update Firebase Auth profile
        await updateProfile(auth.currentUser, {
          displayName: trimmedName || undefined
        })

        // Update Firestore profile
        await updateUserProfile(auth.currentUser.uid, {
          displayName: trimmedName || null
        })

        // Force reload the user data
        await auth.currentUser.reload()
        await refreshUser()
      }
      
      setIsModalOpen(false)
      onUpdate?.()
    } catch (error) {
      console.error('Error updating profile:', error)
      setError('Failed to update profile. Please try again.')
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <>
      <div 
        className="glass-panel p-6 text-center relative group cursor-pointer"
        onClick={() => setIsModalOpen(true)}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsModalOpen(true)
          }
        }}
      >
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center justify-center bg-background/80 backdrop-blur-sm rounded-xl z-10">
          <Button
            isIconOnly
            variant="light"
            onPress={() => setIsModalOpen(true)}
            className="text-primary hover:bg-primary/10 transition-colors"
          >
            <CameraIcon className="w-6 h-6" />
          </Button>
        </div>

        <div className="flex flex-col items-center gap-4 relative z-0">
          {/* Avatar Circle */}
          <div className="relative">
            <div className="w-24 h-24 rounded-full bg-gradient-to-br from-primary to-primary/50 p-[2px]">
              <div className="w-full h-full rounded-full bg-background flex items-center justify-center text-2xl font-medium text-primary">
                {getInitials(displayName)}
              </div>
            </div>
          </div>

          {/* User Info */}
          <div className="flex flex-col items-center gap-1">
            <h3 className="text-lg font-medium text-foreground">
              {displayName || 'Anonymous User'}
            </h3>
            <p className="text-sm text-foreground/60">
              {email}
            </p>
          </div>
        </div>
      </div>

      <Modal 
        isOpen={isModalOpen} 
        onClose={() => {
          setIsModalOpen(false)
          setName(displayName || '')
          setError('')
        }}
        placement="center"
        backdrop="blur"
        classNames={{
          backdrop: "bg-black/50 backdrop-blur-sm",
          base: "border-0",
          body: "py-6",
          header: "border-b-0",
          footer: "border-t-0",
          closeButton: "hover:bg-white/5 active:bg-white/10"
        }}
      >
        <ModalContent>
          <div className="p-6">
            <div className="flex flex-col gap-6">
              {/* Header with Icon */}
              <div className="flex items-center gap-3">
                <div className="h-10 w-10 rounded-xl bg-primary/10 flex items-center justify-center">
                  <CameraIcon className="h-5 w-5 text-primary" />
                </div>
                <div>
                  <h3 className="text-xl font-bold bg-gradient-to-r from-primary to-primary/60 bg-clip-text text-transparent">
                    Edit Profile
                  </h3>
                </div>
              </div>

              {/* Content */}
              <div className="space-y-6">
                {/* Preview */}
                <div className="flex justify-center">
                  <div className="w-20 h-20 rounded-full bg-gradient-to-br from-primary to-primary/50 p-[2px]">
                    <div className="w-full h-full rounded-full bg-background flex items-center justify-center text-xl font-medium text-primary">
                      {getInitials(name || displayName)}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <Input
                    label="Display Name (optional)"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    classNames={{
                      label: "text-foreground/70",
                      input: "text-foreground",
                      inputWrapper: "bg-foreground/5 h-12"
                    }}
                  />
                  <p className="text-foreground/60 text-sm">
                    This name will be displayed on your profile and entries
                  </p>
                </div>

                {error && (
                  <p className="text-danger text-sm">{error}</p>
                )}
              </div>

              {/* Actions */}
              <div className="flex gap-3">
                <Button
                  className="flex-1 h-12 bg-primary/10 text-primary rounded-xl backdrop-blur-xl hover:bg-primary/20 transition-colors"
                  onPress={handleUpdateProfile}
                  isLoading={isUpdating}
                >
                  Save Changes
                </Button>
                <Button
                  className="flex-1 h-12 bg-foreground/5 text-foreground/60 rounded-xl backdrop-blur-xl hover:bg-foreground/10 transition-colors"
                  onPress={() => {
                    setIsModalOpen(false)
                    setName(displayName || '')
                    setError('')
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  )
}