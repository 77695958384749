import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './auth-context';
import { stripe, SubscriptionStatus } from '@/services/stripe';

interface SubscriptionContextType {
  status: SubscriptionStatus;
  isLoading: boolean;
  createCheckoutSession: (priceId: string) => Promise<string>;
  createPortalSession: () => Promise<string>;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

export function SubscriptionProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const [status, setStatus] = useState<SubscriptionStatus>({ isActive: false });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setStatus({ isActive: false });
      setIsLoading(false);
      return;
    }

    const loadSubscription = async () => {
      try {
        const status = await stripe.getSubscriptionStatus();
        setStatus(status);
      } catch (error) {
        console.error('Error loading subscription:', error);
        setStatus({ isActive: false });
      } finally {
        setIsLoading(false);
      }
    };

    loadSubscription();
  }, [user]);

  const value = {
    status,
    isLoading,
    createCheckoutSession: stripe.createCheckoutSession,
    createPortalSession: stripe.createPortalSession,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export function useSubscription() {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
}
